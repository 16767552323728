<template>
    <div class="g-block g-block--small">
        <div class="g-social g-social--paid">
            <div class="conversion-rate conversion-rate--large" v-bind:class="paidClass">
                <div class="grid-container">
                    <div class="col"><p>{{ formatPercentage(lastMonthsPaid) }}</p></div>
                    <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
                </div>
            </div>
            <h3>Facebook paid likes</h3>
            <span class="g-block__num">{{ thisMonthsPaid }}</span>
        </div>
		<div class="g-social g-social--organic">
            <div class="conversion-rate conversion-rate--large" v-bind:class="organicClass">
                <div class="grid-container">
                    <div class="col"><p>{{ formatPercentage(lastMonthsOrganic) }}</p></div>
                    <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
                </div>
            </div>
            <h3>Facebook organic likes</h3>
            <span class="g-block__icon"><i class="fa-duotone fa-thumbs-up"></i></span>
            <span class="g-block__num">{{ thisMonthsOrganic }}</span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import axios from 'axios';

const metric = 'facebookLikes';

var dateFrom = new Date();
var dateTo = new Date();
dateFrom.setDate(dateFrom.getDate() - 57);
dateTo.setDate(dateTo.getDate());
dateFrom = Math.floor(dateFrom.getTime() / 1000);
dateTo = Math.floor(dateTo.getTime() / 1000);

export default {
    data() {
        return {
            isFBReady: false,
            thisMonthsPaid: '--',
            lastMonthsPaid: '--',
            paidClass: '--',
            thisMonthsOrganic: '--',
            lastMonthsOrganic: '--',
            organicClass: '--',
            hasFacebookData: false
        }
    },
    computed: {
        facebookData: {
            get() {
                return this.$store.getters["client/getClientFacebookData"] || ""
            },
        },
        ...mapGetters("client", ["getClientSlug", "getSocial"]),
    },
    created() {
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {return}
            js = d.createElement(s)
            js.id = id
            js.src = "//connect.facebook.net/en_US/sdk.js"
            fjs.parentNode.insertBefore(js, fjs)
            // console.log('setting fb sdk')
        }(document, 'script', 'facebook-jssdk'))
        
        window.fbAsyncInit = function onSDKInit() {
            window.FB.init({
              appId: "766915264635246",
              xfbml: true,
              version: "v14.0",
            });
            window.dispatchEvent(new Event('fb-sdk-ready'))
        }
    },
    methods: {
        // This function fires only when the Facebook SDK has loaded.
        onFBReady: function () {
          this.isFBReady = true
          // console.log('fb ready')

          // Work out the updated date for the likes and either load from the DB or connect to the database
          let lastMonthPaidLikes = 0;
          let lastMonthOrganicLikes = 0;
          let thisMonthPaidLikes = 0;
          let thisMonthOrganicLikes = 0;
          let updatedFacebookData;
          let createNewMetric = false;
          let timeNow = Date.now();
          let timeUpdated = this.dayMilliseconds + 1;
          
          if(this.facebookData){
            if (this.facebookData.metrics){
              if (this.facebookData.metrics[metric]) {
                  timeUpdated = this.facebookData.metrics[metric].updated;
                } else {
                  createNewMetric = true
              }
            }else{
              createNewMetric = true
            }
          }else{
            createNewMetric = true
          }

          axios.get(`/api/clients/facebookData/${this.getClientSlug}`)
            .then((response) => {

              let timeSince = timeNow - timeUpdated;

              updatedFacebookData = response.data;

              if (timeSince < this.dayMilliseconds) {

                  let _this = this; // inside the loadjs/FB bits this refers to something else
                    // console.log(response.data)
                    /*
                    * The data is over a day old, so update from the API and save the updated information to the database.
                    */

                    if (createNewMetric) {
                      updatedFacebookData.metrics[metric] = {}
                    }
                    window.FB.api('/' + this.getSocial.tokens.meta.facebookSlug + '/insights?pretty=0&since=' + dateFrom + '&until=' + dateTo + '&metric=page_fan_adds_by_paid_non_paid_unique&access_token=' + this.getSocial.tokens.meta.pageAccessToken, function(likesResponse) {
  
                    let likesArray = likesResponse.data[0].values;
                    for (var oldLikesIndex = 0; oldLikesIndex < 28; oldLikesIndex++) {
                          lastMonthPaidLikes = lastMonthPaidLikes + likesArray[oldLikesIndex].value.paid;
                          lastMonthOrganicLikes = lastMonthOrganicLikes +  likesArray[oldLikesIndex].value.unpaid;
                    }
                    for (var newLikesIndex = 28; newLikesIndex < 56; newLikesIndex++) {
                          thisMonthPaidLikes = thisMonthPaidLikes + likesArray[newLikesIndex].value.paid;
                          thisMonthOrganicLikes = thisMonthOrganicLikes +  likesArray[newLikesIndex].value.unpaid;
                    }

                    updatedFacebookData.metrics[metric].paidPrevious = lastMonthPaidLikes
                    updatedFacebookData.metrics[metric].paidCurrent = thisMonthPaidLikes
                    updatedFacebookData.metrics[metric].organicPrevious = lastMonthOrganicLikes
                    updatedFacebookData.metrics[metric].organicCurrent = thisMonthOrganicLikes
                    updatedFacebookData.metrics[metric].updated = Date.now();
                    _this.thisMonthsPaid = thisMonthPaidLikes
                    _this.lastMonthsPaid = (((parseFloat(thisMonthPaidLikes) / parseFloat(lastMonthPaidLikes)) * 100) - 100).toFixed(1)
                    _this.thisMonthsOrganic = thisMonthOrganicLikes
                    _this.lastMonthsOrganic = (((parseFloat(thisMonthOrganicLikes) / parseFloat(lastMonthOrganicLikes)) * 100) - 100).toFixed(1)
                    _this.setFacebookData(updatedFacebookData.metrics[metric], metric)
                    // Set the class based on the rate
                    _this.paidClass = _this.conversionRate(_this.lastMonthsPaid)
                    _this.organicClass = _this.conversionRate(_this.lastMonthsOrganic);

                    _this.$store.commit('client/setClientFacebookData', {metric: metric, data: updatedFacebookData.metrics[metric]});

                  } ); 
              }

              this.$store.commit('client/setClientFacebookData', {metric: metric, data: updatedFacebookData.metrics[metric]});

            })
            .catch((err) => console.error('error calulating time since update:', err));

        },
        componentHasAllData(){
    
            let newVal = {
              vue: 'organicSocialComponents',
              component: 'FacebookLikesBlock',
              val: true
            }

            this.$store.commit('client/setPageLoaded', newVal);

        },
        setFacebookData(){
          if(this.facebookData.metrics){
            if (this.facebookData.metrics[metric]) {
                  // 2. If the data does exist replace the time updated
                  this.thisMonthsPaid = this.facebookData.metrics[metric].paidCurrent
                  this.lastMonthsPaid = (((parseFloat(this.facebookData.metrics[metric].paidCurrent) / parseFloat(this.facebookData.metrics[metric].paidPrevious)) * 100) - 100).toFixed(1)
                  this.thisMonthsOrganic = this.facebookData.metrics[metric].organicCurrent
                  if(parseFloat(this.facebookData.metrics[metric].organicCurrent) === 0 && parseFloat(this.facebookData.metrics[metric].organicPrevious) === 0){
                    this.lastMonthsOrganic = 0;
                  }else{
                    this.lastMonthsOrganic = (((parseFloat(this.facebookData.metrics[metric].organicCurrent) / parseFloat(this.facebookData.metrics[metric].organicPrevious)) * 100) - 100).toFixed(1)
                  }
                  // Set the class based on the rate
                  this.paidClass = this.conversionRate(this.lastMonthsPaid)
                  this.organicClass = this.conversionRate(this.lastMonthsOrganic)
                  this.componentHasAllData();
            }else{
              console.log('error: this.facebookData.metrics[metric] underfined', [this.facebookData.metrics, metric]);
            }
          }else{
              console.log('error: this.facebookData.metrics underfined', this.facebookData);
            }
        }
    },
    watch:{
      'facebookData': {
        deep: true,
            async handler() {
                this.setFacebookData();
            }
        }
    },
    mounted() {

      this.isFBReady = window.FB != undefined
      window.addEventListener('fb-sdk-ready', this.onFBReady);
      this.setFacebookData();
    },
}
</script>
