<template>
	<div class="g-block g-block--small">
		<div class="g-social g-social--organic">
            <div class="conversion-rate conversion-rate--large" v-bind:class="organicClass">
                <div class="grid-container">
                    <div class="col"><p>{{ formatPercentage(lastMonthsOrganic) }}</p></div>
                    <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
                </div>
            </div>
            <h3>Facebook organic engagement</h3>
            <span class="g-block__icon"><i class="fa-duotone fa-message-dots"></i></span>
            <span class="g-block__num">{{ thisMonthsOrganic }}</span>
        </div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import axios from 'axios';

const metric = 'facebookEngagement';


var dateFrom = new Date();
var dateTo = new Date();
dateFrom.setDate(dateFrom.getDate() - 57);
dateTo.setDate(dateTo.getDate());
dateFrom = Math.floor(dateFrom.getTime() / 1000);
dateTo = Math.floor(dateTo.getTime() / 1000);

export default {
    data() {
        return {
            isFBReady: false,
            thisMonthsOrganic: '--',
            lastMonthsOrganic: '--',
            organicClass: '--',
        }
    },
    computed: {
        facebookData: {
            get() {
                return this.$store.getters["client/getClientFacebookData"] || ""
            },
        },
        ...mapGetters("client", ["getClientSlug", "getSocial"]),
    },
    created() {
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {return}
            js = d.createElement(s)
            js.id = id
            js.src = "//connect.facebook.net/en_US/sdk.js"
            fjs.parentNode.insertBefore(js, fjs)
            // console.log('setting fb sdk')
        }(document, 'script', 'facebook-jssdk'))
        
        window.fbAsyncInit = function onSDKInit() {
            window.FB.init({
              appId: "766915264635246",
              xfbml: true,
              version: "v14.0",
            });
            window.dispatchEvent(new Event('fb-sdk-ready'))
        }
    },
    methods: {
        // This function fires only when the Facebook SDK has loaded.
        onFBReady: function () {
          this.isFBReady = true
          // console.log('fb ready')

          // Work out the updated date for the likes and either load from the DB or connect to the database
          let lastMonthEngagements = 0;
          let thisMonthEngagements = 0;
          let updatedFacebookData;
          let createNewMetric = false;
          let timeNow = Date.now();
          let timeUpdated = this.dayMilliseconds + 1;

        //  console.log('LUKE this.facebookData', this.facebookData);
          
          if(this.facebookData){
            if (this.facebookData.metrics){
              if (this.facebookData.metrics[metric]) {
                  timeUpdated = this.facebookData.metrics[metric].updated;
                } else {
                  createNewMetric = true
              }
            }else{
              createNewMetric = true
            }
          }else{
            createNewMetric = true
          }
  
          let timeSince = timeNow - timeUpdated;

          axios.get(`/api/clients/facebookData/${this.getClientSlug}`)
            .then((response) => {

              let _this = this; // inside the loadjs/FB bits this refers to something else

              // console.log(response.data)
              /*
               * The data is over a day old, so update from the API and save the updated information to the database.
               */
              updatedFacebookData = response.data;

              if (createNewMetric) {
                if(updatedFacebookData.metrics){
                  updatedFacebookData.metrics[metric] = {}
                }
              }

              if (timeSince > this.dayMilliseconds) {

                window.FB.api('/' + this.getSocial.tokens.meta.facebookSlug + '/insights?pretty=0&since=' + dateFrom + '&until=' + dateTo + '&metric=page_positive_feedback_by_type&access_token=' + this.getSocial.tokens.meta.pageAccessToken, function(engagementsResponse) {

                console.log('LUKE engagementsResponse', engagementsResponse.data[0].values)

                let engagementsArray = engagementsResponse.data[0].values;
                for (var oldEngagementsIndex = 0; oldEngagementsIndex < 28; oldEngagementsIndex++) {
                    let thisDaysAnswers = engagementsArray[oldEngagementsIndex].value.answer
                    let thisDaysClaims = engagementsArray[oldEngagementsIndex].value.claim
                    let thisDaysComments = engagementsArray[oldEngagementsIndex].value.comment
                    let thisDaysLikes = engagementsArray[oldEngagementsIndex].value.like
                    let thisDaysLinks = engagementsArray[oldEngagementsIndex].value.link
                    let thisDaysOther = engagementsArray[oldEngagementsIndex].value.other
                    let thisDaysRSVPs = engagementsArray[oldEngagementsIndex].value.rsvp
                    // Need to check these are numbers and not undefined
                    if (Number.isInteger(thisDaysAnswers)) { thisMonthEngagements = thisMonthEngagements + thisDaysAnswers; }
                    if (Number.isInteger(thisDaysClaims)) { thisMonthEngagements = thisMonthEngagements + thisDaysClaims; }
                    if (Number.isInteger(thisDaysComments)) { thisMonthEngagements = thisMonthEngagements + thisDaysComments; }
                    if (Number.isInteger(thisDaysLikes)) { thisMonthEngagements = thisMonthEngagements + thisDaysLikes; }
                    if (Number.isInteger(thisDaysLinks)) { thisMonthEngagements = thisMonthEngagements + thisDaysLinks; }
                    if (Number.isInteger(thisDaysOther)) { thisMonthEngagements = thisMonthEngagements + thisDaysOther; }
                    if (Number.isInteger(thisDaysRSVPs)) { thisMonthEngagements = thisMonthEngagements + thisDaysRSVPs; }
                }
                for (var newEngagementsIndex = 28; newEngagementsIndex < 56; newEngagementsIndex++) {
                    let thisDaysAnswers = engagementsArray[newEngagementsIndex].value.answer
                    let thisDaysClaims = engagementsArray[newEngagementsIndex].value.claim
                    let thisDaysComments = engagementsArray[newEngagementsIndex].value.comment
                    let thisDaysLikes = engagementsArray[newEngagementsIndex].value.like
                    let thisDaysLinks = engagementsArray[newEngagementsIndex].value.link
                    let thisDaysOther = engagementsArray[newEngagementsIndex].value.other
                    let thisDaysRSVPs = engagementsArray[newEngagementsIndex].value.rsvp
                    // Need to check these are numbers and not undefined
                    if (Number.isInteger(thisDaysAnswers)) { lastMonthEngagements = lastMonthEngagements + thisDaysAnswers; }
                    if (Number.isInteger(thisDaysClaims)) { lastMonthEngagements = lastMonthEngagements + thisDaysClaims; }
                    if (Number.isInteger(thisDaysComments)) { lastMonthEngagements = lastMonthEngagements + thisDaysComments; }
                    if (Number.isInteger(thisDaysLikes)) { lastMonthEngagements = lastMonthEngagements + thisDaysLikes; }
                    if (Number.isInteger(thisDaysLinks)) { lastMonthEngagements = lastMonthEngagements + thisDaysLinks; }
                    if (Number.isInteger(thisDaysOther)) { lastMonthEngagements = lastMonthEngagements + thisDaysOther; }
                    if (Number.isInteger(thisDaysRSVPs)) { lastMonthEngagements = lastMonthEngagements + thisDaysRSVPs; }
                }
                // Take off the paid likes as this metric doesn't differentiate
                // Possible flaw in this is there might not be a value if that data hasn't loaded, but that would only be for the first 24 hours of a client existing.

                if(Object.keys(updatedFacebookData.metrics).length > 1){

                  lastMonthEngagements = lastMonthEngagements - updatedFacebookData.metrics.facebookLikes.paidPrevious
                  thisMonthEngagements = thisMonthEngagements - updatedFacebookData.metrics.facebookLikes.paidCurrent

                }
                updatedFacebookData.metrics[metric].previous = lastMonthEngagements
                updatedFacebookData.metrics[metric].current = thisMonthEngagements
                updatedFacebookData.metrics[metric].updated = Date.now();
                // _this.thisMonthsPaid = thisMonthPaidEngagements
                // _this.lastMonthsPaid = (((parseFloat(thisMonthPaidEngagements) / parseFloat(lastMonthPaidEngagements)) * 100) - 100).toFixed(1)
                _this.thisMonthsOrganic = thisMonthEngagements
                _this.lastMonthsOrganic = (((parseFloat(thisMonthEngagements) / parseFloat(lastMonthEngagements)) * 100) - 100).toFixed(1)
                _this.setFacebookData(updatedFacebookData.metrics[metric], metric)
                // Set the class based on the rate
                // _this.paidClass = _this.conversionRate(_this.lastMonthsPaid)
                _this.organicClass = _this.conversionRate(_this.lastMonthsOrganic)

                });

                _this.$store.commit('client/setClientFacebookData', {metric: metric, data: updatedFacebookData.metrics[metric]});

              }

              _this.$store.commit('client/setClientFacebookData', {metric: metric, data: updatedFacebookData.metrics[metric]});

            })
            .catch((err) => console.error('error calulating time since update:', err));

        },
        componentHasAllData(){
    
            let newVal = {
              vue: 'organicSocialComponents',
              component: 'FacebookEngagementsBlock',
              val: true
            }

            this.$store.commit('client/setPageLoaded', newVal);

        },
        setFacebookMetricData(){
          if(this.facebookData.metrics){

            if (this.facebookData.metrics[metric]) {
                // 2. If the data does exist replace the time updated
                // this.thisMonthsPaid = this.facebookData.metrics[metric].paidCurrent
                // this.lastMonthsPaid = (((parseFloat(this.facebookData.metrics[metric].paidCurrent) / parseFloat(this.facebookData.metrics[metric].paidPrevious)) * 100) - 100).toFixed(1)
                this.thisMonthsOrganic = this.facebookData.metrics[metric].current
                this.lastMonthsOrganic = (((parseFloat(this.facebookData.metrics[metric].current) / parseFloat(this.facebookData.metrics[metric].previous)) * 100) - 100).toFixed(1)
                // Set the class based on the rate
                // this.paidClass = this.conversionRate(this.lastMonthsPaid)
                this.organicClass = this.conversionRate(this.lastMonthsOrganic);

                this.componentHasAllData();

            }

            }else{

            console.log('error: this.facebookData.metrics underfined', this.facebookData);

            }
        }
    },
    watch:{
      'facebookData': {
        deep: true,
            async handler() {
                this.setFacebookMetricData();
            }
        }
    },
    mounted() {

        this.isFBReady = window.FB != undefined
        window.addEventListener('fb-sdk-ready', this.onFBReady)
        this.setFacebookMetricData();
    },
}
</script>
