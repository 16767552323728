<template>
    <div class="g-wrapper organic">
		<button class="g-top" @click="toTop">
			<span class="g-top__text">Top</span>
            <span class="g-top__arrow">
                <i class="fa-solid fa-arrow-up-long"></i>
            </span>
		</button>
		<HeaderGraphBlock></HeaderGraphBlock>
        <section class="g-main">
            <div class="g-blocks g-blocks--2">
                <div class="g-blocks__col">
                    <FacebookEngagementsBlock></FacebookEngagementsBlock>
                    <FacebookLikesBlock></FacebookLikesBlock>
                    <FacebookConversionsBlock></FacebookConversionsBlock>
                </div>
                <div class="g-blocks__col">
                    <ConversionsBlock></ConversionsBlock>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import HeaderGraphBlock from "../../components/social/HeaderGraphBlock.vue"
import FacebookEngagementsBlock from "../../components/social/FacebookEngagementsBlock.vue"
import FacebookLikesBlock from "../../components/social/FacebookLikesBlock.vue"
import FacebookConversionsBlock from "../../components/social/FacebookConversionsBlock.vue"
import ConversionsBlock from "../../components/social/ConversionsBlock.vue"

export default {
    components: {
        HeaderGraphBlock,
        FacebookEngagementsBlock,
        FacebookLikesBlock,
        FacebookConversionsBlock,
        ConversionsBlock,
    },
    computed: {
        clientPagesLoaded: {
            get() {
				return this.$store.getters["client/getClientPagesLoaded"] || ""
            },
		},
        headerGraphBlockReset: {
            get() {
				return this.$store.getters["client/getHeaderGraphBlockReset"] || ""
            },
		},
        ...mapGetters("client", ["getClientSlug"]),
    },
    watch: {

        'clientPagesLoaded.organicSocialComponents': {
            handler: function(newValue) {
                console.log('comp-loaded:', newValue);
                if (Object.values(newValue).every(val => val === true)) {

                    this.$store.commit('client/setHidePageLoader', true);
                }else{
                    this.$store.commit('client/setHidePageLoader', false);
                }
              //  this.$store.commit('client/setHidePageLoader', true);
            },
            deep: true
        },

    },  
    methods: {
        handleScroll() {
            // Any code to be executed when the window is scrolled
            this.isUserScrolling = (window.scrollY > 0);
            if ( window.scrollY > 30 ) {
                document.querySelector(".g-wrapper").classList.add('has-scrolled');
            } else {
                document.querySelector(".g-wrapper").classList.remove('has-scrolled');
            }
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss">
@import "./src/assets/scss/views/google.scss";
</style>
